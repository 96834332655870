class Timer {
  constructor() {
    this.intervalID = undefined;
    this.remainingTime = 0;
  }

  getRemainingTime() {
    return Math.ceil(this.remainingTime / 1000);
  }

  start(seconds, handleNextTick) {
    return new Promise(resolve => {
      this.cancel();

      this.remainingTime = seconds * 1000;
      const handleInterval = () => {
        this.remainingTime -= 1000;

        if (this.remainingTime <= 0) {
          this.cancel();
          resolve();

        } else if (
            handleNextTick && typeof handleNextTick === 'function'
        ) {
          handleNextTick(this.getRemainingTime());
        }
      };

      this.intervalID = setInterval(handleInterval, 1000);
    });
  }

  cancel() {
    if (typeof this.intervalID === 'number') {
      clearInterval(this.intervalID);
      this.intervalID = undefined;
      this.remainingTime = 0;
    }
  }
}

export const sharedTimer = new Timer();

export {Timer};