import moment from "moment";

export default {
  instance(date) {
    if ( date ) return moment(date)
    return moment();
  },

  emailDate(_date, full_date = false) {

    const
        date = new Date(_date),
        today = new Date();

    const is_today = (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDay() === today.getDay());

    const today_format = "h:mm a";
    const not_today_format = full_date ? "DD/MM/YYYY h:mm a" : "DD/MM/YYYY";

    return moment(new Date(date)).format(is_today ? today_format : not_today_format);

  },

  prettyDate(date) {
    return moment(date, 'YYYY-MM-DD').format("DD MMMM YYYY");
  },

  prettyFullDate(date) {
    return moment(new Date(date), 'YYYY-MM-DD hh:mm:ss A').format("DD MMMM YYYY hh:mm:ss A");
  },

  entryDate(date) {
    return moment(new Date(date), 'YYYY-MM-DD hh:mm:ss A').format("DD/MMM/YYYY hh:mm A");
  },

  formatDate(date) {
    return moment(new Date(date), "YYYYMMDDhmmss").fromNow();
  },

  customFormat(date, format) {
    if ( !date ) return moment().format(format || "YYYY-MM-DD");
    return moment(date).format(format || "YYYY-MM-DD");
  },

  parseDate(_date) {
    if ( !_date ) return ''
    const x = moment(new Date())
    const y = moment(_date)
    return moment.duration(x.diff(y)).humanize();
  },

  addMinutesToDate(minutes, date) {
    const _date = new Date(date) || new Date();
    return moment(_date).add(minutes, 'minutes').format();
  },

  addHoursToDate(hours, date) {
    const _date = new Date(date) || new Date();
    return moment(_date).add(hours, 'hours').format();
  },

  addDaysToDate(days, date) {
    const _date = new Date(date) || new Date();
    return moment(_date).add(days, 'days').toDate();
  },

  nextDayOfTheWeek() { // Tomorrow day (return a moment object)
    return moment().day(moment().day() + 1).startOf('day');
  },

  dayOfNextWeek(day) { // Add 7 days to day number passed by param (return a moment object)
    return moment().day(day).add(7, 'days').startOf('day');
  },

  getDaysInMonth(_month, _year) {
    if ( !(_month >= 1 && _month <= 12) )
      return [];
    else {
      const
          month = _month - 1,
          date = new Date(_year, month, 1),
          days = [];

      while ( date.getMonth() === month ) {
        days.push(moment(new Date(date), 'YYYY-MM-DD').format("DD MMMM YYYY"));
        date.setDate(date.getDate() + 1);
      }

      return days;
    }
  },

  prettyMonth(month) {
    const months = [
      {
        "name": "January",
        "abbreviation": "Jan"
      },
      {
        "name": "February",
        "abbreviation": "Feb"
      },
      {
        "name": "March",
        "abbreviation": "Mar"
      },
      {
        "name": "April",
        "abbreviation": "Apr"
      },
      {
        "name": "May",
        "abbreviation": "May"
      },
      {
        "name": "June",
        "abbreviation": "Jun"
      },
      {
        "name": "July",
        "abbreviation": "Jul"
      },
      {
        "name": "August",
        "abbreviation": "Aug"
      },
      {
        "name": "September",
        "abbreviation": "Sep"
      },
      {
        "name": "October",
        "abbreviation": "Oct"
      },
      {
        "name": "November",
        "abbreviation": "Nov"
      },
      {
        "name": "December",
        "abbreviation": "Dec"
      },
    ];

    return months[month];
  },

  endOf(date, period) { // period: day | month | year
    if ( date ) return moment(date).endOf(period);
    return moment().endOf(period);
  },

  startOf(date, period) { // period: day | month | year
    if ( date ) return moment(date).startOf(period);
    return moment().startOf(period);
  },

  subtract(date, number, period) { // period: day | month | year
    if ( date ) return moment(date).subtract(number, period);
    return moment().subtract(number, period);
  },

  isSameDay(date1, date2) {
    return moment(date1).isSame(date2, 'day');
  },

  isSameMonth(date1, date2) {
    return moment(date1).isSame(date2, 'month');
  },

  isSameYear(date1, date2) {
    return moment(date1).isSame(date2, 'year');
  },

  isBetween(date, start, end) {
    try {
      return moment(date).isBetween(start, end, 'minutes', '[]');
    } catch (e) {
      return false;
    }
  },

  difference(start, end, unitOfTime, precise) {
    return moment(start).diff(moment(end), unitOfTime, precise);
  },

  msToTime(ms, precision = 3) {
    const duration = moment.duration(ms);
    const items = [];

    items.push({ timeUnit: 'd', value: Math.floor(duration.asDays()) });
    items.push({ timeUnit: 'h', value: duration.hours() });
    items.push({ timeUnit: 'm', value: duration.minutes() });
    items.push({ timeUnit: 's', value: duration.seconds() });
    items.push({ timeUnit: 'ms', value: duration.milliseconds() });

    const formattedItems = items.reduce((accumulator, { value, timeUnit }) => {
      if ( accumulator.length >= precision || (accumulator.length === 0 && value === 0) ) {
        return accumulator;
      }

      accumulator.push(`${ value }${ timeUnit }`);
      return accumulator;
    }, []);

    return formattedItems.length ? formattedItems.join(' ') : '-';
  },

  secondsToTime(sec, precision = 3) {
    const duration = moment.duration(sec, 'seconds');
    const items = [];

    items.push({ timeUnit: 'd', value: Math.floor(duration.asDays()) });
    items.push({ timeUnit: 'h', value: duration.hours() });
    items.push({ timeUnit: 'm', value: duration.minutes() });
    items.push({ timeUnit: 's', value: duration.seconds() });

    const formattedItems = items.reduce((accumulator, { value, timeUnit }) => {
      if ( accumulator.length >= precision || (accumulator.length === 0 && value === 0) ) {
        return accumulator;
      }

      accumulator.push(`${ value }${ timeUnit }`);
      return accumulator;
    }, []);

    return formattedItems.length ? formattedItems.join(' ') : '-';
  },

  isAfter(date, after, type) {
    try {
      return moment(date).isAfter(after, type || 'minutes');
    } catch (e) {
      return false;
    }
  },

  isBefore(date, before) {
    try {
      return moment(date).isBefore(before, 'minutes');
    } catch (e) {
      return false;
    }
  },

  birthdayFormat(date, format = "YYYY-MM-DD") {
    if ( !date ) {
      return moment(new Date(), "YYYY-MM-DD").format(format);
    }
    return moment(date, "YYYY-MM-DD").format(format);
  },
}