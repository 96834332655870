const colors = ['#673ab7', '#f44336', '#03a9f4', '#2196f3', '#00bcd4', '#607d8b', '#ff9800', '#cddc39', '#8bc34a', '#009688', '#789efe', '#e1bee7', '#1a237e'];


export default function randomColor(hash) {
    const length = colors.length;

    let output = 0;
    for (let i = 0; i < hash.length; i++) {
        output += +hash[i].charCodeAt(0);
    }
    return colors[output % length];

}
