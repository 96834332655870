import moment from 'moment';

const prettyDate = {};

prettyDate.install = function (Vue) {
    Vue.filter('pretty-date', (date) => {
        return moment(date,'YYYY-MM-DD').format("DD MMMM YYYY");
    })
}

export default prettyDate;