const scrollToBottom = (el, smooth) => {
    el.scroll({
        top: el.scrollHeight,
        behavior: smooth ? 'smooth' : 'instant'
    });
};

const vAutoScroll = {
    bind: (el, binding) => {
        let timeout;
        let scrolled = false;

        el.addEventListener('scroll', () => {
            if (timeout) window.clearTimeout(timeout);
            timeout = window.setTimeout(function() {
                scrolled = el.scrollTop + el.clientHeight + 1 < el.scrollHeight;
            }, 200);
        });

        (new MutationObserver(e => {
            let config = binding.value || {};
            let pause = config.always === false && scrolled;
            if (pause
                ||
                e.reduce((pre, cur) => {
                    return pre+cur.addedNodes.length
                }, 0) < 1
            ) return;
            scrollToBottom(el);
        })).observe(el, {childList: true, subtree: true});
    },
    inserted: scrollToBottom
};

export default vAutoScroll;


