import axios from 'axios';

class HttpClient {
  constructor(options = {}) {
    this.instance = axios.create(options);
  }

  async get(apiPath, params = {}, headers = {}) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    headers['Content-Type'] = 'application/json';
    headers['key'] = localStorage.getItem('hash');
    return axios({ url: apiPath, method: 'GET', params, headers });
  }

  async post(apiPath, params = {}, headers = {}) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    headers['Content-Type'] = 'application/json';
    headers['key'] = localStorage.getItem('hash');
    return axios({ url: apiPath, method: 'POST', data: params, headers });
  }

  async put(apiPath, params = {}, headers = {}) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    headers['Content-Type'] = 'application/json';
    headers['key'] = localStorage.getItem('hash');
    return axios({ url: apiPath, method: 'PUT', data: params, headers });
  }

  async delete(apiPath, params, headers = {}) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    headers['Content-Type'] = 'application/json';
    headers['key'] = localStorage.getItem('hash');
    return axios({ url: apiPath, method: 'DELETE', data: params, headers });
  }

  async upload(apiPath, params = {}, headers = {}) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    headers['Content-Type'] = 'multipart/form-data';
    headers['key'] = localStorage.getItem('hash');
    return axios({ url: apiPath, method: 'POST', data: params, headers });
  }

  async download(apiPath, params = {}, headers = {}) {
    headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    headers['Content-Type'] = headers['Content-Type'] ?? 'application/json';
    headers['key'] = localStorage.getItem('hash');
    return axios({ url: apiPath, method: 'GET', params, headers, responseType: 'blob' });
  }
}

export default HttpClient;