import Vue from 'vue';
import App from '@/App.vue';
import VueSocketIO from "vue-socket.io";
import io from 'socket.io-client';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VDragged from 'v-dragged';
import Vuelidate from 'vuelidate';
import AudioVisual from 'vue2-audio-visual';
import Datetime from 'vue-datetime'
import VueCarousel from 'vue-carousel';
import * as VueGoogleMaps from 'gmap-vue';
import VueHtml2Canvas from 'vue-html2canvas';
import VueYoutube from 'vue-youtube';

Vue.use(VueHtml2Canvas);

import '@/helpers/import.ui.components';
import 'vue-datetime/dist/vue-datetime.css'

import Grid from './components/Grid/index';

import EventBus from '@/plugins/event-bus';
import VueAutoScroll from '@/plugins/vue-auto-scroll';
import VueLoadIndicator from '@/plugins/vue-load-indicator';
import { i18n } from '@/plugins/i18n';
import { bus } from '@/helpers/bus';
import router from '@/router';
import MD5 from '@/helpers/MD5';

import PrettyDate from '@/filters/pretty-date';

import store from '@/store/store';
import config from '@/config'
import HttpClient from '@/helpers/http.client';
import randomColor from '@/helpers/randomColor';
import VueInsProgressBar from 'vue-ins-progress-bar';
import VCalendar from 'v-calendar';
import VueApexCharts from 'vue-apexcharts';
import VueRx from 'vue-rx';

import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd";
import "vuejs-clipper/dist/vuejs-clipper.css";

import geolocation from '@/plugins/geolocation';

Vue.use(geolocation);

const options = {
  position: 'absolute',
  show: true,
  height: '3px'
};

Vue.component('apexchart', VueApexCharts);

const env = config.stage;
const server_base = config[env].ws_server;

Vue.prototype.$http = new HttpClient();

Vue.prototype.$playNotificationSound = function () {
  let audio = new Audio(`${ server_base }/sounds/notification.mp3`);
  audio.play();
};

Vue.prototype.$playReceiveSound = function () {
  let audio = new Audio(`${ server_base }/sounds/receive.mp3`);
  audio.play();
};

Vue.prototype.$playSendSound = function () {
  let audio = new Audio(`${ server_base }/sounds/send.mp3`);
  audio.play();
};

Vue.prototype.$md5 = function (string, key = 'super.4.EZL34D$') {
  return MD5(string, key);
};

Vue.prototype.$notify = function (data) {
  bus.$emit('NOTIFY', data);
};

Vue.prototype.$sendSMS = function (data) {
  bus.$emit('SHOW_SMS_MODAL', data);
};

Vue.prototype.$getUser = function () {
  return store.getters.user;
};

Vue.prototype.$getImpersonate = function () {
  if ( localStorage.getItem('impersonate') !== null && localStorage.getItem('impersonate') !== "" )
    return localStorage.getItem('impersonate');
  return null;
};

Vue.prototype.$getCompany = function () {
  return JSON.parse(localStorage.getItem('user')).Company;
};

Vue.prototype.$getActive = function () {
  if ( JSON.parse(localStorage.getItem('user')).Company ) {
    return JSON.parse(localStorage.getItem('user')).Company.active;
  }

  return false;
};

Vue.prototype.$normalizeNumber = function (text) {
  let number = text.replace(/\(|\)/g, '');
  number = number.replace(/\./g, '');
  number = number.replace(/-/g, '');
  number = number.replace(/ /g, '');
  number = number.slice(-10);
  return number
};

Vue.prototype.$hasRole = function (role) {
  const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);
  const capitalizedRole = capitalize(role);
  const userPermissions = store.getters.user?.permissions;
  return userPermissions ? userPermissions[`is${ capitalizedRole }`] : false;
};


Vue.prototype.$ws_server = function () {
  return config[env].ws_server;
};

Vue.prototype.$calls_server = function () {
  return config[env].calls_server;
};

Vue.prototype.$reviews = function () {
  return config[env].reviews;
};

Vue.prototype.$inventory_server = function () {
  return config[env].inventory_server;
};

Vue.prototype.$s3_url = function (bucket) {
  if ( bucket ) {
    return `${ config[env].s3_url }${ bucket }/`;
  }
  return config[env].s3_url;
};

Vue.prototype.$files_server = function () {
  return config[env].files_server;
};

Vue.prototype.$cdn_server = function () {
  return config[env].cdn_server;
};

Vue.prototype.$short_url = function () {
  return config[env].short_url;
};

Vue.prototype.$mailing_server = function () {
  return config[env].mailing;
};

Vue.prototype.$meet = function () {
  return config[env].meet;
};

Vue.prototype.$meet_server = function () {
  return config[env].meet_server;
};

Vue.prototype.$data_server = function () {
  return config[env].data_server;
};

Vue.prototype.$call = function (data) {
  bus.$emit('START_CALL', data);
};

Vue.prototype.$getColor = function (string) {
  return randomColor(string);
};

Vue.prototype.$hasLoading = function (value) {
  const index = store.state.loading.indexOf(value);
  return index >= 0;
};

Vue.prototype.$anyLoading = function () {
  return store.state.loading.length > 0;
};

Vue.prototype.$agentAvatar = function () {
  const user = JSON.parse(localStorage.getItem('user'));
  return `${ server_base }/api/files/avatar/${ user._id }`;
};

Vue.prototype.$hideModals = function () {
  bus.$emit('HIDE_ALL_MODALS');
};

Vue.prototype.$Uploading = function () {
  return store.state.uploading;
};

Vue.prototype.$Downloading = function () {
  return store.state.downloading;
};

Array.prototype.unique = function () {
  const a = this.concat();
  for ( let i = 0; i < a.length; ++i ) {
    for ( let j = i + 1; j < a.length; ++j ) {
      if ( a[i] === a[j] )
        a.splice(j--, 1);
    }
  }

  return a;
};

Vue.config.productionTip = env === 'production' || env === 'staging';

let ioInstance = io(config[env].ws_server + '/dashboard', {
  autoConnect: false,
  reconnection: true,
  reconnectionDelay: 200,
  transports: ['websocket']
});

//Vue.use(VueSocketIO, ioInstance, store);

Vue.use(new VueSocketIO({
  connection: ioInstance,
  vuex: {
    store
  }
}));
Vue.use(VueAutoScroll);
Vue.use(VueCarousel);
Vue.use(VueLoadIndicator);
Vue.use(Vuelidate);
Vue.use(VueInsProgressBar, options);
Vue.use(AudioVisual);
Vue.use(VDragged);
Vue.use(Grid);
Vue.use(Datetime);
Vue.use(VueYoutube);
Vue.use(EventBus);
Vue.use(PrettyDate);
Vue.use(VCalendar, {});
Vue.use(VueReCaptcha, { siteKey: '6LdvQa4UAAAAACsnR57K81JeRZa0VhVhLsLPJ4cc', loaderOptions: { autoHideBadge: true } });
Vue.use(VueGoogleMaps, {
  load: {
    key: config[env].google_maps_api_key,
    libraries: 'places,drawing,geometry',
  }
});
Vue.use(VueRx);

Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperPreview: true
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
