import Vue from 'vue';
import EZLoader from "@/components/EZLoader/index";

const vLoadIndicator = {
    bind: (el, binding) => {
        const ComponentClass = Vue.extend(EZLoader);
        const instance = new ComponentClass({
            propsData: {
                bg: binding.value.bg,
                color: binding.value.color,
                type: binding.value.type,
                size: binding.value.size,
            }
        });

        instance.$mount();
        el.appendChild(instance.$el);

        if (binding.value.class) {
            el.classList.add(binding.value.class);
        } else {
            el.classList.add('ez_loader_container');
        }
    },
};

export default vLoadIndicator;